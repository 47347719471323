import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./index";
import { LoginContext } from "../LoginContext";

const UserProvider = (props) => {
  const { supabase } = useContext(LoginContext);
  const [userData, setUserData] = useState([]);
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    getProductData();
  }, []);

  const getProductData = async () => {
    const { data } = await supabase.from("products").select();
    const value = await supabase.from('dummy_data').select();
    setTableData(value.data);
    setUserData(data);
  };
  return (
    <UserContext.Provider
      value={{
        userData,
        tableData,
        ...props,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
