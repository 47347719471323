import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthRoute = ({ Component }) => {
  const navigate = useNavigate();
  const login = localStorage.getItem("user");

  useEffect(() => {
    if (!login) {
      toast.error("User is not LogIn..!");
      navigate("/signIn");
      return;
    }
  }, []);

  return <>{login && <Component />}</>;
};

export default AuthRoute;
