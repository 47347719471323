import React, { useContext, useState } from "react";
import { UserContext } from "../../helper/UserContext";
import ProductDetail from "./ProductDetail";

const TableHead = ["Id", "Title", "Color", "Category", "Price","Product Id", "Action"];
const Product_List = () => {
  const { userData } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState({});

  const handleOpen = (productId) => {
    const size =
      window.innerWidth < window.innerHeight
        ? window.innerWidth/2
        : window.innerHeight/2; 
    const left = window.innerWidth / 2 - size / 2;
    const top = window.innerHeight / 2 - size / 2;

    const windowFeatures = `width=${size},height=${size},left=${left},top=${top},scrollbars`;

    window.open(
      `https://3dm-loader.pages.dev/${productId}`,
      "_blank",
      windowFeatures
    );
  };
  return (
    <div className="pt-28 flex flex-col px-28 dark:bg-gray-800">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow-sm ">
            <table className="min-w-full rounded-md divide-gray-300">
              <thead className="bg-gray-50 dark:bg-gray-600 dark:border-spacing-1 dark:border-gray-100">
                <tr>
                  {TableHead.map((item, index) => (
                    <th
                      key={index}
                      className="py-3.5 text-center font-semibold text-gray-900 dark:text-white"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {userData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.id}
                    </td>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.title}
                    </td>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.color}
                    </td>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.category}
                    </td>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.price}
                    </td>
                    <td className="text-center whitespace-nowrap px-3 py-4 dark:bg-gray-800 dark:text-gray-300 text-gray-500">
                      {item.product_id}
                    </td>
                    <td className="relative text-center whitespace-nowrap py-4 pl-3 dark:bg-gray-800 dark:text-gray-300 font-medium">
                      <span
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        // onClick={()=>{setModal(true);setProduct(item)}}
                        onClick={()=>{handleOpen(item.product_id)}}
                      >
                        Product Veiw
                          </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <ProductDetail open={modal} setOpen={setModal} Product={product} /> */}
    </div>
  );
};

export default Product_List;
