import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import LoginProvider from "./helper/LoginContext/LoginProvider";
import { Route, Routes } from "react-router-dom";
import SignIn from "./Components/LoginPages/SignIn";
import SignUp from "./Components/LoginPages/SignUp";
import Header from "./Components/Header";
import AuthRoute from "./AuthRoute";
import Product_List from "./Components/Product_List";
import Theme from "./Components/Theme";
import UserProvider from "./helper/UserContext/UserProvider";
import { ToastContainer } from "react-toastify";
import Project from "./Components/Project";
import Modals from "./Components/Modals";
// import ModalView from "./Components/VirtualScroll";

function App() {
  return (
    <div className="data min-h-[100vh] bg-background">
      <LoginProvider>
        <UserProvider>
          <Header />
          <Routes>
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/" element={<AuthRoute Component={Product_List} />} />
            <Route path="/theme" element={<AuthRoute Component={Theme} />} />
            <Route
              path="/modals"
              element={<AuthRoute Component={Modals} />}
            />
            <Route
              path="/project"
              element={<AuthRoute Component={Project} />}
            />
            {/* <Route
              path="/modalview"
              element={<AuthRoute Component={ModalView} />}
            /> */}
          </Routes>
        </UserProvider>
      </LoginProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
