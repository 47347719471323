// import React from "react";

// const Project = () => {
//   const SETTINGS = {
//     itemHeight: 15,
//     amount: 15,
//     tolerance: 5,
//     minIndex: 0,
//     maxIndex: 10000,
//     startIndex: 1,
//   };
//   const getData = (offset, limit, tableData) => {
//     console.log('call get');
//     const data = [];
//     const start = Math.max(SETTINGS.minIndex, offset);
//     const end = Math.min(offset + limit - 1, SETTINGS.maxIndex);
//     console.log(
//       `request [${offset}..${offset + limit - 1}] -> [${start}..${end}] items`
//     );
//     if (start <= end) {
//       for (let i = start; i <= end; i++) {
//         console.log(tableData, "asdasdjhu");
//         data.push({
//           id: tableData[i].id,
//           title: tableData[i].title,
//           type: tableData[i].type,
//           price: tableData[i].price,
//           profit: tableData[i].profit,
//         });
//       }
//     }
//     return data;
//   };

//   const rowTemplate = (item) => (
//     // console.log(item,'item')
//     // <tr className="item" key={item.id}>
//     //   s<td>{item.id}</td>
//     //   <td>{item.title}</td>
//     //   <td>{item.type}</td>
//     //   <td>{item.price}</td>
//     //   <td>{item.storage}</td>
//     //   <td>{item.profit}</td>
//     // </tr>
//     <div className="item" key={item.id}>
//       {item.id}
//       {item.title}
//       {item.type}
//     </div>
//   );
//   return (
//     <>
//       {/* <VirtualScroll settings={SETTINGS} getData={getData} row={rowTemplate} /> */}
//     </>
//   );
// };

// export default Project;

import React, { useContext, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { UserContext } from "../../helper/UserContext";

const Project = () => {
  const { tableData } = useContext(UserContext);
  // const containerStyle = useMemo(
  //   () => ({ width: "100%", height: "100%" }),
  //   []
  // );
  const gridStyle = useMemo(
    () => ({
      height: window.innerHeight - 100,
      width: window.innerWidth - 100
    }),
    []
  );
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", maxWidth: 100,marginLeft:'50px' },
    { field: "title", filter: "agMultiColumnFilter" },
    { field: "type", filter: "agMultiColumnFilter" },
    { field: "price", filter: "agNumberColumnFilter" },
    { field: "storage", filter: "agMultiColumnFilter" },
    { field: "profit", filter: "agNumberColumnFilter" },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 200,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  return (
    <div  className="ml-auto w-auto h-auto">
      <div className="ag-theme-alpine h-[100vh] mx-20 pt-20">
        <AgGridReact
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default Project;
