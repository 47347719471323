import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  MoonIcon,
  SunIcon
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { LoginContext } from "../../helper/LoginContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const { setThemeDark, themeDark, LogoutUser } = useContext(LoginContext);

  return (
    <Disclosure as="nav" className="bg-background border-b-2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-20">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                </div>
                <h1 className="text-textColor ml-2 text-xl">Logo</h1>
              </div>
              <div>
                <div className="flex space-x-4">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-indigo-500"
                        : "text-textColor") +
                      " px-3 py-2 font-medium hover:text-indigo-500 bg-background "
                    }
                  >
                    Product List
                  </NavLink>
                  <NavLink
                    to="/theme"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-indigo-500"
                        : "text-textColor") +
                      " px-3 py-2 font-medium hover:text-indigo-500 bg-background "
                    }
                  >
                    Theme
                  </NavLink>
                  <NavLink
                    to="/modals"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-indigo-500"
                        : "text-textColor") +
                      " px-3 py-2 font-medium hover:text-indigo-500 bg-background "
                    }
                  >
                    Modals
                  </NavLink>
                  <NavLink
                    to="/project"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-indigo-500"
                        : "text-textColor") +
                      " px-3 py-2 font-medium hover:text-indigo-500 bg-background "
                    }
                  >
                    Projects
                  </NavLink>
                  {/* <NavLink
                    to="/modalview"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-indigo-500"
                        : "text-textColor") +
                      " px-3 py-2 font-medium hover:text-indigo-500 bg-background "
                    }
                  >
                    Modal View
                  </NavLink> */}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="rounded-full text-gray-100  bg-gray-800  p-1 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-400"
                    onClick={() => {
                      setThemeDark(!themeDark);
                    }}
                  >
                    {themeDark ? (
                      <SunIcon className="h-6 w-6" />
                    ) : (
                      <MoonIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-400">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={LogoutUser}
                            >
                              Sign out
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
