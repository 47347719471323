import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import ModatData from "./ModatData";
import { LoginContext } from "../../helper/LoginContext";
import moment from "moment";

const Modals = () => {
  const { supabase } = useContext(LoginContext);
  const [fileList, setFiles] = useState([]);

  useEffect(() => {
    getData();
    removeData();
  }, []);

  const getData = async () => {
    const { data, error } = await supabase.from("import_files").select();
    if (data) {
      setFiles(data);
    }
  };

  // first time remove data from backend
  const removeData = async () => {
    const { data, error } = await supabase.storage
      .from("import_modal")
      .remove(["modals/"]);
    if (error) {
      console.log(error, "error while remove bucket files..!");
    }
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = async ({ meta, file }, status) => {
    console.log(status, "status");
    if (status === "done") {
      const dateString = moment().format("MMMM Do YYYY, h:mm:ss a");
      // check file in storage
      const object = fileList.find((item) => item.fileName === file.name);

      if (!object && file) {
        // add file in supabase storage bucket
        const { data, error } = await supabase.storage
          .from("import_modal")
          .upload("modals/" + file?.name, file);
        if (!error) {
          // get url from supabas storage bucket for add in new table
          const { data } = supabase.storage
            .from("import_modal")
            .getPublicUrl("modals/" + file.name);

          if (data) {
            // add url and create new row with auto generate uuid
            const { error } = await supabase.from("jewel_data").insert({
              url: data.publicUrl,
            });
            if (!error) {
              // get uuid from supabase jewel table for create new table data id
              const value = await supabase
                .from("jewel_data")
                .select()
                .eq("url", data.publicUrl);

              // add data in insert file table in supabase
              const { error } = await supabase.from("import_files").insert({
                id: value.data[0].id,
                fileName: file.name,
                size: file.size,
                status: "Temp",
                createAt: dateString,
              });
              getData(); // --> get updated data in table view (update state)
            }
          }
        }
      }
    }
    if (status === "removed") {
      // const object = fileList.find((item) => item.fileName === file.name);

      // if (object) {
      //   const { error } = await supabase
      //     .from("jewel_data")
      //     .delete()
      //     .eq("id", object.id);

      //   if (!error) {
      //     const { data, error } = await supabase.storage
      //       .from("import_modal")
      //       .remove(["modals/" + object.fileName]);
      //   }
      // }
      // const newArray = fileList.filter((item) => item.fileName !== file.name);
      // setFiles(newArray);
    }
  };

  const handleSubmit = async (files) => {
    console.log(files, "submit files");
    for (let index = 0; index < files.length; index++) {
      const element = files[index].file;
      const { data, error } = await supabase.storage
        .from("import_modal")
        .upload("modals/" + element?.name, element);
      if (error) {
        console.log(error, "error", element.name);
      } else {
        console.log(data, "uploaded", element.name);
      }
    }
  };

  return (
    <div className=" mt-10">
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        styles={{
          dropzone: {
            maxWidth: 1500,
            minWidth: 100,
            minHeight: 150,
            maxHeight: 400,
            // margin: "20px 100px",
          },
        }}
        // accept="image/*,audio/*,video/*3dm/*glb"
      />
      <ModatData data={fileList} />
    </div>
  );
};

export default Modals;
